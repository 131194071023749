<template>
  <div class="main">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="box justify box-bottom-border" >
        <div class="box center mB20">
          <el-form-item prop="startTime" label="分析时段">
            <el-date-picker placeholder="请选择时间" @change="clearTable" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.startTime"> </el-date-picker>
          </el-form-item>
          <span class="line">-</span>
          <el-form-item prop="endTime">
            <el-date-picker @change="clearTable" placeholder="请选择时间" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.endTime"> </el-date-picker>
          </el-form-item>
          <el-button @click="querryData" class="mL44 dataQuery">查询</el-button>
        </div>
        <span @click="queryHistory" class="mB20 dataQuery checkHistory pointer" :class="{ activeColorHis: ruleForm.period == 1 }">查看历史</span>
      </div>
      <div class="box center mB20 mT20">
        <el-form-item label="监测类型" prop="type" style="margin-right: 44px;">
          <el-select @change="changeType" v-model="ruleForm.type">
            <el-option v-for="item in typeList" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="quotaList.length > 0" class="" label="监测指标" prop="quota">
        <el-radio-group @change="clearTable" v-model="ruleForm.quota">
          <el-radio v-for="item of quotaList" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
        <!--        <div v-if="ruleForm.period === 2" class="box center ml24 smallLabel">-->
        <div v-if="false" class="box center ml24 smallLabel">
          <span class="el-form-item__label wid96">参数录入</span>
          <el-form-item label="采样频率" prop="samplingFrequency">
            <el-input v-model="ruleForm.samplingFrequency" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item class="ml24" label="分段长度" prop="fractionLength">
            <el-input v-model="ruleForm.fractionLength" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
      </div>

    </el-form>
    <template  v-if="tableData.length">
      <div class="box-bottom-border" style="padding-bottom: 20px;">
        <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table-column align="center" prop="target.code" label="测点编号"></el-table-column>
          <el-table-column align="center" prop="target.site" label="位置描述">
            <template slot-scope="scope">{{ scope.row.target.site || "—" }}</template>
          </el-table-column>
          <el-table-column align="center" prop="timeDuration" label="时段长度" v-if="ruleForm.period == 1"
            >timeDuration
            <template slot-scope="scope">{{ scope.row.frequencyDuration === 0 ? scope.row.frequencyDurationOther : scope.row.frequencyDuration | frequencyDuration }}</template>
          </el-table-column>
          <el-table-column align="center" prop="frequencyPeriod" label="时间间隔(s)" v-if="ruleForm.period == 1">
            <template slot-scope="scope">{{ scope.row.frequencyPeriod || "—" }}</template>
          </el-table-column>
          <el-table-column align="center" prop="id" label="操作">
            <template slot-scope="scope">
              <span @click="look(scope.row)" class="pointer operate-txt">查看数据</span>
            </template>
          </el-table-column>
          <template slot="empty">
            <span class="iconfont iconzanwushuju-"></span>
            <span>暂无数据</span>
          </template>
        </el-table>
        <common-pagination class="pageMain" :hiddenTotal="true" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
      </div>
    </template>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
    <div v-show="showTimeMain" class="chartMain">
      <div class="fs16 box center mT20 mB20">
        <img style="margin-right: 12px;width: 14px;height: 14px;" src="@/assets/images/fragment-title-icon.png" />
        <div style="margin-right: 20px;">测点编号：{{ targetName }}</div>
        <div v-if="targetHour" class="pl40">时域分析频率：{{ targetHour }}</div>
      </div>
      <div class="box justify smallTableMain flexTop">
        <div class="flex1">
          <div class="newBox mB10" v-show="!showNoData1" >
            <p class="emColor title  mB10">监测信号时程曲线</p>
            <div class="myChart" id="myChart1"></div>
          </div>
          <div class="noDataMain" v-show="showNoData1">
            <p class="emColor title  mB10">监测信号时程曲线</p>
              <img src="../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
          <div class="newBox" v-show="!showNoData2">
            <p class="emColor title  mB10">平均功率谱</p>
            <div class="myChart" id="myChart2"></div>
          </div>
          <div class="noDataMain" v-show="showNoData2">
            <p class="emColor title  mB10">平均功率谱</p>
              <img src="../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
        </div>
        <table style="width:460px; margin-left:20px;">
           <tr>
             <!-- <td>准静态时域统计值</td> -->
             <th>序号</th>
             <th>本次峰值(dBW/Hz)</th>
             <th>优势频率(Hz)</th>
           </tr>
           <tr  v-for="(item,index) in msgList" :key="index">
             <td>{{index + 1}}</td>
             <td>{{item.value}}</td>
             <td>{{item.freValue}}</td>
           </tr>
        </table>
        <!-- <el-table :data="msgList" v-loading="loadingSmall" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table-column align="center" type="index" width="58px" label="序号"></el-table-column>
          <el-table-column align="center" prop="value" label="本次峰值   (dBW/Hz)"></el-table-column>
          <el-table-column align="center" prop="freValue" label="优势频率(Hz)"></el-table-column>
        </el-table> -->
      </div>
    </div>
    <template v-if="showHistoryMain">
      <p class="emColor mB16 mT20">历史峰值统计（测点编号：{{ targetName }}）</p>
      <el-table :row-class-name="tableRowClassName" class="historyTable" :data="historyTable" tooltip-effect="dark" style="width: 100%" v-loading="loadingHistory" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-table-column align="center" width="295px" prop="time" label="时间"></el-table-column>
        <el-table-column align="center" prop="data" label="峰值(dBW/Hz)/频率(Hz)">
          <el-table-column align="center" v-for="(item, index) in columnList" :key="index" prop="time">
            <template slot-scope="scope">{{ !scope.row.data[index] ? "—" : scope.row.data[index] }}</template>
            <!--            <template slot-scope="scope">{{scope.row.data}}</template>-->
          </el-table-column>
        </el-table-column>
        <template slot="empty">
          <span class="iconfont iconzanwushuju-"></span>
          <span>暂无数据</span>
        </template>
      </el-table>
      <common-pagination class="pageMain" :pageSize="pageSize" :total="totalH" :currentPage="currentPageH" @handleCurrentChange="handleCurrentChangeH" />
    </template>
  </div>
</template>

<script>
import echarts from "echarts";
import CommonPagination from "../../../components/Pagination";
import filters from "@/assets/js/filter";
let option = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 40,
      end: 60,
      bottom: 10,
      textStyle: {
          color: '#58637f' //滚动条两边字体样式
      },
      fillerColor: "rgba(39, 114, 240, 0.2)", //选中范围的填充颜色
      handleColor: "#194fdb", //滑块两端的线
      borderColor: "rgba(255,255,255,0.00)" //边框
      // yAxisIndex: [0],
      // filterMode: "empty"
    },
    {
      type: "inside",
      realtime: true,
      start: 40,
      end: 60
    }
  ],
  tooltip: {
    show: true,
    trigger: "axis"
  },
  grid: {
    top: "30",
    left: "80",
    right: "16",
    bottom: "80"
  },
  xAxis: {
    type: "category",
    nameLocation: "end",
    nameGap: 0,
    nameRotate: 270,
    axisLabel: {
      color: "#cdd0d8",
      fontSize: 14,
      formatter: function (value) {
        return value.split(" ").join("\n");
      },
      rotate: 4,
      showMinLabel: false
    },
    axisLine: {
      lineStyle: {
        color: "#1c2b51",
        // type: "dashed"
      }
    },
    axisTick: {
      show: false
    },
    nameTextStyle: {
      color: "#fff"
      // padding: [20, 0, 0,-60]
    },
    // name:'时间',
    data: []
  },
  yAxis: [
    {
      type: "value",
      name: "幅值",
      nameTextStyle: {
        color: "#58637f",
        fontSize: 14
        // padding: [0, 0, -6,10]
      },
      axisLabel: {
        color: "#58637f",
        fontSize: 14
      },
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      splitLine: {
        lineStyle: {
          color: "#1c2b51",
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      axisTick: {
        show: false
      }
    }
  ],
  series: [
    {
      name: "Y值",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#6DB5C7"
      },
      lineStyle: {
        width: 1
      }
    }
  ]
};
import mixin from "./mixin";
import moment from "moment";
export default {
  name: "timeDomainAnalysis",
  components: {
    CommonPagination
  },
  mixins: [mixin],
  data() {
    return {
      showNoData1:false,
      showNoData2:false,
      tableData: [],
      msgList: [],
      historyTable: [],
      columnList: [],
      pageSize: 5,
      currentPage: 1,
      currentPageH: 1,
      total: 0,
      totalH: 0,
      loading: false,
      loadingHistory: false,
      loadingSmall: false,
      ruleForm: { period: 2, type: "", startTime: "", endTime: "", quota: "" },
      rules: {},
      periodList: [
        { name: "历史查询", id: 1 },
        { name: "重新计算", id: 2 }
      ],
      typeList: [],
      quotaList: [],
      myChart1: {},
      myChart2: {},
      showHistoryMain: false,
      showTimeMain: false,
      size: 10000,
      sort: 1,
      targetName: "",
      targetUnit: "",
      targetTime: "",
      targetId: "",
      dataType: 12
    };
  },
  mounted() {
    this.changeTimeDay(2);
    // this.ruleForm.endTime = new Date().Format('yyyy-MM-dd hh:mm')
    // this.ruleForm.startTime = new Date(new Date().getTime() - (30 * 60 * 1000)).Format('yyyy-MM-dd hh:mm')
    this.getTypeList(); // 请求监测类型
  },
  methods: {
    queryHistory() {
      console.log("查看历史", this.ruleForm.period);
      if (this.ruleForm.period == 1) {
        console.log("查看历史", this.ruleForm.period);
        this.ruleForm.period = 2;
      } else {
        this.ruleForm.period = 1;
        console.log("查看历史else", this.ruleForm.period);
      }
      this.clearTable0();
      this.querryData();
    },
    changeTimeDay(n) {
      var day = new Date();
      var day1 = day.getTime(); //获得当前时间戳
      var day2 = day1 - n * 60 * 60 * 1000;
      var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      var end = moment(day).format("YYYY-MM-DD HH:mm:ss");

      this.ruleForm.startTime = start;
      this.ruleForm.endTime = end;
    },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    clearTable0() {
      if (this.ruleForm.period == 1) {
        this.changeTimeDay(30 * 24);
      }
      if (this.ruleForm.period == 2) {
        this.changeTimeDay(2);
      }
    },

    clearTable() {
      this.showTimeMain = false;
      this.showHistoryMain = false;
      this.tableData = [];
      this.querryData();
    },
    initEcharts1(xData, yData) {
      let thisOption = { ...option };
      thisOption.yAxis[0].name = "幅值" + this.targetUnit;
      thisOption.yAxis[0].nameTextStyle.padding = [0, 0, -6, 10];
      thisOption.xAxis.data = xData;
      thisOption.xAxis.name = "";
      thisOption.xAxis.axisLabel.showMaxLabel = true;
      thisOption.series[0].name = "幅值";
      thisOption.series[0].data = yData;
      let max = Math.max.apply(null, yData).toFixed(3);
      let min = Math.min.apply(null, yData).toFixed(3);
      thisOption.yAxis[0].max = max;
      thisOption.yAxis[0].min = min;
      // this.myChart1 = echarts.init(document.getElementById("myChart1"));
      // this.myChart1.setOption(thisOption);
      // window.addEventListener("resize", () => {
      //   this.myChart1.resize();
      // });
      var name = "幅值" + this.targetUnit;
      var lineColor = '#54a6ff';
      this.$chart2.line1('myChart1', xData, yData, name, lineColor, max, min);

    },
    initEcharts2(xData, yData) {
      let thisOption = { ...option };
      thisOption.yAxis[0].name = "功率谱密度(dBW/Hz)";
      thisOption.xAxis.name = "频率(Hz)";
      thisOption.xAxis.data = xData;
      // thisOption.xAxis.nameTextStyle.padding = [0, 0, 0,-60]
      // thisOption.xAxis.nameTextStyle.padding = [0, 0, -300,-60]
      thisOption.yAxis[0].nameTextStyle.padding = [0, 0, -6, 140];
      this.myChart2 = echarts.init(document.getElementById("myChart2"));
      thisOption.series[0].lineStyle.type = "dotted";
      thisOption.series[0].data = yData;
      thisOption.series[0].name = "功率谱密度值";
      let max = Math.max.apply(null, yData).toFixed(3);
      let min = Math.min.apply(null, yData).toFixed(3);
      thisOption.yAxis[0].max = max;
      thisOption.yAxis[0].min = min;
      // this.myChart2.setOption(thisOption);
      // window.addEventListener("resize", () => {
      //   console.log(window.innerWidth);
      //   this.myChart2.resize();
      // });

      max = Number(max) + (Number(max)*0.3)
      min = Number(min) + (Number(min)*0.3)

      console.log('max', max);
      console.log('min', min);

      var name = "功率谱密度(dBW/Hz)";
      var xname = '频率(Hz)'
      var name2 = '功率谱密度值'
      var lineColor = '#86eaff'
      this.$chart2.line2('myChart2', xData, yData, name, lineColor, xname, name2, '', '');

      var chart1 = echarts.init(document.getElementById('myChart1'))
      var chart2 = echarts.init(document.getElementById('myChart2'))
      echarts.connect([chart1, chart2])
      
    },
    changeType() {
      this.clearTable();
      // this.getQuotaList()
      this.getQuotaTypeList();
    },
    querryData() {
      let endTime = new Date(this.ruleForm.endTime).getTime() / 1000 / 60;
      let startTime = new Date(this.ruleForm.startTime).getTime() / 1000 / 60;
      let cha = (endTime - startTime) / 60 / 24;
      if (startTime > endTime) {
        this.$message({
          message: "开始时间不能晚于结束时间",
          type: "warning"
        });
        return;
      } else if (cha > 365) {
        this.$message({
          message: "时间间隔应小于365天",
          type: "warning"
        });
        return;
      }
      this.handleCurrentChange(1);
      this.showTimeMain = false;
      this.showHistoryMain = false;
    },
    look(item) {
      this.targetId = item.target.id;
      this.targetName = item.target.code;
      this.targetUnit = item.target.unit;
      let frequencyDuration = ["其他", "1分钟", "5分钟", "10分钟", "15分钟", "20分钟", "0.5小时", " 1小时", "1.5小时"];
      this.targetTime = item.frequencyDuration === 0 ? item.frequencyDurationOther : frequencyDuration[item.frequencyDuration];
      // this.targetId = 82
      if (this.ruleForm.period === 1) {
        this.handleCurrentChangeH(1);
        this.showTimeMain = false;
        this.showHistoryMain = true;
      } else {
        this.getAssist();
        this.getCleanData();
        this.showTimeMain = true;
        this.showHistoryMain = false;
      }
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.loadingHistory = true;
      this.getTable();
    },
    handleCurrentChangeH(val) {
      console.log(val);
      this.currentPageH = val;
      this.getHistoryList();
    },
    getTypeList() {
      // this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
      let params = {
        dataType: this.dataType,
        projectId: sessionStorage.getItem("projectId")
      };

      this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.typeList = res.data;
          this.ruleForm.type = res.data[0].id;
          // this.getQuotaList()
          this.getQuotaTypeList();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getQuotaList() {
      let params = {
        typeGroupId: this.ruleForm.type
      };
      this.$axios.get(`${this.baseURL}base/baseTargetType/byGroupId`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.quotaList = res.data;
          this.ruleForm.quota = res.data[0].id;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getTable() {
      let params = {
        projectId: this.$store.state.projectId,
        type: this.ruleForm.quota,
        current: this.currentPage,
        size: this.pageSize
      };
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/type/${this.currentPage}/${this.pageSize}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        console.log(res);
        if (res.errCode === "200") {
          this.tableData = data.records;
          if (this.tableData.length) {
            this.look(this.tableData[0]);
          }
          this.total = data.total;
          this.loadingHistory = false;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getHistoryList() {
      // 请求历史数据table
      let params = {
        targetId: this.targetId,
        current: this.currentPageH,
        size: this.pageSize,
        sort: this.sort,
        startTime: !this.ruleForm.startTime ? "" : this.ruleForm.startTime + ":00",
        endTime: !this.ruleForm.endTime ? "" : this.ruleForm.endTime + ":00"
      };
      this.$axios.get(`${this.baseURL}data/history/page/fdomain/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        if (res.errCode === "200") {
          console.log(res);
          let list = data.datas;
          // this.columnList = list.length>0?list[0].data.splice(0,5):[]
          let dataItem = [];
          let historyTable = [];
          for (let item1 of list) {
            dataItem = [];
            let item = item1.data;
            let obj1 = item[5].toFixed(3) + "/" + item[0].toFixed(3);
            let obj2 = item[6].toFixed(3) + "/" + item[1].toFixed(3);
            let obj3 = item[7].toFixed(3) + "/" + item[2].toFixed(3);
            let obj4 = item[8].toFixed(3) + "/" + item[3].toFixed(3);
            let obj5 = item[9].toFixed(3) + "/" + item[4].toFixed(3);
            dataItem.push(obj1, obj2, obj3, obj4, obj5);
            console.log(dataItem);
            let obj = {
              time: item1.time,
              data: dataItem
            };
            historyTable.push(obj);
          }
          this.columnList = historyTable.length > 0 ? historyTable[0].data : [];
          this.historyTable = historyTable;
          this.totalH = data.total;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getAssist() {
      // 请求辅助信息
      this.loadingSmall = true;
      let params = {
        targetId: this.targetId,
        startTime: this.ruleForm.startTime + ":00",
        endTime: this.ruleForm.endTime + ":00"
      };
      this.$axios.get(`${this.baseURL}data/hisData/fdomain/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          let list = res.data.peak || [];
          let lineData = res.data.curve || [];
          if(lineData && lineData.length){
            this.showNoData2 = false
          }else{
            this.showNoData2 = true
          }
          let xData = [];
          let yData = [];
          for (let item of lineData) {
            xData.push(item[0]);
            yData.push(item[1]);
          }
          this.initEcharts2(xData, yData);
          let msgList = [];
          for (let item of list) {
            let obj = {
              value: item[1].toFixed(3),
              freValue: item[0].toFixed(3)
            };
            msgList.push(obj);
          }
          this.msgList = msgList;
        } else {
          this.$message.error(res.errMsg);
          this.showNoData2 = true
          this.initEcharts2([], []);
        }
        this.loadingSmall = false;
      }).catch(err=>{
        this.showNoData2 = true
      });
    },
    getCleanData() {
      // 请求清洗数据 折线图绿线
      let params = {
        targetId: this.targetId,
        sort: 1,
        startTime: this.ruleForm.startTime + ":00",
        endTime: this.ruleForm.endTime + ":00",
        size: this.size
      };
      this.$axios.get(`${this.baseURL}data/history/based/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        if(data && data.length){
          this.showNoData1 =false
        }else{
          this.showNoData1 =true
        }
        let xData = [];
        let yData = [];
        if (res.errCode === "200") {
          console.log(data);
          for (let item of data) {
            xData.unshift(item.time);
            // xData.push(new Date(item.time).Format("hh:mm:ss"));
            yData.unshift(item.data[0]);
          }
          this.initEcharts1(xData, yData);
        } else {
          this.$message.error(res.errMsg);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  ::v-deep .el-form {
    margin-top: 24px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 96px;
      padding: 0;
    }

    .smallLabel {
      .el-form-item__label {
        width: 80px;
        font-weight: 300;

        &.wid96 {
          width: 96px;
        }
      }
    }

    .el-form-item {
      margin-bottom: 0;
      display: flex;

      &.mB20 {
        margin-bottom: 20px;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }
    }

    .line {
      padding: 0 10px;
    }

    .el-input {
      width: 134px;
      height: 30px;

      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }

      .el-select__caret {
        line-height: 30px;
      }

      &.el-date-editor {
        width: 158px;

        .el-input__inner {
          padding: 0 0 0 8px;
          font-size: 14px;
        }

        .el-input__prefix {
          display: none;
        }
      }
    }
  }

  ::v-deep .el-radio-group {
    .el-radio__inner {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border: 1px solid #787878;

      &:after {
        width: 6px;
        height: 6px;
      }
    }

    .el-radio__label {
      color: #fff;
      font-size: 16px;
    }
  }

  .pageMain {
    margin-top: 20px;
  }

  .chartMain {
    position: relative;
    .title {
      width: 100%;
      // position: absolute;
      // // top: -32px;

      // .pl40 {
      //   padding-left: 40px;
      // }
    }
  }

  .myChart {
    //width: 100%;
    height: 200px;
    overflow: hidden;
  }

  .smallTableMain {
    .el-table {
      margin-top: 50px;
    }
  }

  ::v-deep .historyTable.el-table {
    border: none;

    thead.is-group.has-gutter {
      th {
        background-color: #2e373c;
      }

      tr:nth-child(2) {
        display: none;
      }
    }

    .cell {
      padding: 0;
    }

    &.el-table--border::after,
    &.el-table--group::after {
      width: 0;
    }
  }
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
.noDataMain{
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 40px;
  background: #0D234A;
  margin-bottom: 20px;
}

.title {
  position: relative;
  left: 14px;
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
}
.title::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -12px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
</style>
