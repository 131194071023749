export default {

    methods: {
          //  查询测点类型  zhw
    getQuotaTypeList() {
        let params = {
          dataType: this.dataType,
          groupId: this.ruleForm.type,
          projectId: sessionStorage.getItem("projectId")
        };
        this.$axios.get(`${this.baseURL}data_management/data_type/group/type`, { params }).then((res) => {
          res = res.data;
            if (res.errCode === "200") {
              console.log("获取测点列表qqq",res.data)
            this.quotaList = res.data;
              this.ruleForm.quota = res.data[0].id;
              this.querryData()
          } else {
            this.$message.error(res.errMsg);
          }
        });
      },
  
    },
}